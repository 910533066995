// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ResidentialRental from "./components/ResidentialRental";
import ProtectedRoute from "./components/ProtectedRoute";
import FilterPage from "./components/FilterPage";
import Footer from "./components/Footer";
import PaymentStatusPage from "./components/PaymentStatusPage";
import SavedProperties from "./components/SavedProperties";
import Profile from "./components/Profile";

const App = () => {
  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault();

    const handleKeyDown = (e) => {
      if (
        e.key === "F12" ||
        (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "J")) ||
        (e.ctrlKey && e.key === "U")
      ) {
        e.preventDefault();
      }
    };

    const checkDevToolsOpen = () => {
      const threshold = 150;
      const devToolsCurrentlyOpen =
        window.outerWidth - window.innerWidth > threshold ||
        window.outerHeight - window.innerHeight > threshold;

      if (devToolsCurrentlyOpen) {
        localStorage.setItem("devToolsOpen", "true");
        // Preserve the current history state and redirect to Google
        window.history.replaceState(null, "", window.location.href);
        window.location.replace("https://www.google.com");
      } else {
        localStorage.removeItem("devToolsOpen");
      }
    };

    const preventBackNavigation = () => {
      const popstateHandler = () => {
        // When dev tools are open, allow back navigation
        if (localStorage.getItem("devToolsOpen") === "true") {
          window.history.replaceState(null, "", window.location.href);
          // If user tries to go back, keep them on the current page
        } else {
          window.history.pushState(
            null,
            "https://www.google.com",
            window.location.href
          );
          // Else, allow normal back navigation when dev tools are closed
        }
      };

      // Only manipulate history if dev tools were detected
      if (localStorage.getItem("devToolsOpen") === "true") {
        window.history.pushState(
          null,
          "https://www.google.com",
          window.location.href
        );
      }

      window.addEventListener("popstate", popstateHandler);

      return () => {
        window.removeEventListener("popstate", popstateHandler);
      };
    };

    // Attach event listeners for context menu and keydown
    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);
    window.addEventListener("resize", checkDevToolsOpen);
    const cleanupPopstate = preventBackNavigation();

    // Initial check for dev tools status
    checkDevToolsOpen();

    return () => {
      // Clean up event listeners
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("resize", checkDevToolsOpen);
      cleanupPopstate(); // Clean up the popstate listener
    };
  }, []);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/filter"
          element={<ProtectedRoute element={FilterPage} />}
        />
        <Route
          path="/residential-rental"
          element={<ProtectedRoute element={ResidentialRental} />}
        />
        <Route
          path="/saved-properties"
          element={<ProtectedRoute element={SavedProperties} />}
        />
        <Route
          path="/my-profile"
          element={<ProtectedRoute element={Profile} />}
        />
        <Route
          path="/payment-success"
          element={<ProtectedRoute element={PaymentStatusPage} />}
        />
        {/* Add more routes as needed */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
